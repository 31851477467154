<template>
  <div class="head-outer">
    <div class="head-guding"></div>
 <div class="head">
    <div class="head-inner">
      <div >
        <a href="/">  <img src="../../public/img/head-logo.png" alt=""></a>

      </div>
      <div class="head-inner-name" style="margin-left: -300px">
        <p style="height: 63px;line-height: 63px">BEST TITANIUM PRODUCER</p>
<!--        <p.home-custom-review>SINCE 2008</p.home-custom-review>-->
      </div>
      <div class="head-inner-content">
        <router-link to="/home">
        <div class="head-inner-content-item">

            Home
          </div>
        </router-link>
        <router-link to="product">
        <div class="head-inner-content-item">
          Product
        </div>
        </router-link>
        <router-link to="company">
        <div class="head-inner-content-item">
          Company
        </div>
        </router-link>
        <router-link to="tecNew">
        <div class="head-inner-content-item">
          Technology
        </div>
        </router-link>
        <router-link to="news">
        <div class="head-inner-content-item">
          News
        </div>
        </router-link>
          <router-link to="contact">
        <div class="head-inner-content-item">
          Contact Us
        </div>
          </router-link>
      </div>
    </div>





 </div>
<!--    <div class="cebianyouxiang">-->
<!--      <div>-->
<!--      <img src="../../public/img/cebian-youxiang.png" alt="">-->
<!--      </div>-->
<!--      <div class="cebianyouxiang-lianxi">-->

<!--      </div>-->
<!--    </div>-->

    <div class="youxiang">
      <div @click="changeNum()">
        <img src="../../public/img/cebian-youxiang.png" alt="" class="" v-if="num==0">
        <img src="../../public/img/cebian-xx.png" alt="" class="" v-if="num==1">
      </div>
      <div class="youxiang-right" v-if="num==1">
        <input type="text" placeholder=" Email" v-model="contactForm.email">
        <input type="text" placeholder=" Name" style="margin-left: 10px" v-model="contactForm.name">
        <textarea name="" id="" cols="30" rows="10" placeholder=" Message" v-model="contactForm.message"></textarea>
        <a >
          <div class="send-button" v-loading="loading" @click="postContact()">
            Send
          </div>
        </a>
      </div>
    </div>

    <div class="back-to-top" @click="scrollTopReal" v-if="scroll>1000">
      <img src="../../public/img/cebian-to-top.png" alt="" class="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {

    msg: String
  },
  data(){
    return{
      num:0,
      loading:false,
      contactForm:{
        name: "",
        jobTitle: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        message: ""
      },
      scroll:''
    }
  },
  methods:{
    changeNum(){
      if (this.num==0){
        this.num = 1;
      }else {
        this.num = 0;
      }
    },
    postContact(){
      const is=this.formCheck(this.contactForm,2)
      if(is.is_ok==true){
        if(this.loading==false){
          this.loading=true
          this.$axios.post('/api/pushContact',this.contactForm).then(res=>{
            this.loading=false
            if(res.data.code==200){
              this.$message.success('Successed!');
            }else{
              this.$message.error('Failed!');
            }
          })
        }
      }else if(is.is_empty==true){
        // this.loading=false
        // this.$message.warning('The form can\'t be empty!');
        this.$message({message: 'The form can\'t be empty!',type: 'warning',offset:430,})
      }else{
        this.$message({message: 'Failed!',type: 'error',offset:430,})
      }
    },
    scrollTopReal(element, to, duration){
      if (duration <= 0) return;
      var difference = to - element.scrollTop;
      var perTick = difference / duration * 10;
      setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
      }, 10);
    },
    scrollTop(){
      this.scroll = document.documentElement.scrollTop||document.body.scrollTop;
      // console.log(this.scroll)

    },
  },
  mounted() {
    window.addEventListener('scroll',this.scrollTop)

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.cebianyouxiang{
  position: fixed;
  right: 0px;
  top: 300px;
  z-index: 900;
  display: flex;

}
.cebianyouxiang-lianxi{
  width: 406px;
  height: 368px;
  background: white;
}
.back-to-top{
  position: fixed;
  z-index: 900;
  right: 0px;
  top: 700px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
text-decoration: none;
}
.router-link-exact-active .head-inner-content-item{
  background: #258B3B;
}
.youxiang{
  position: fixed;
  right: 0px;
  top: 300px;
  display: flex;
  z-index: 900;
}
.youxiang-right{
  width: 403px;
  height: 316px;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0px 4px 4px 0px;
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-evenly;
  padding-top: 31px;
  padding-left: 32px;
  padding-right: 20px;
}
.youxiang-right input{
  width: 165px;
  height: 35px;
  background: #F7F7F7;
  border: 0px;


}
.youxiang-right input:focus{
  outline: none;
}
.youxiang-right textarea{
  width: 340px;
  height: 144px;
  background: #F7F7F7;
  //margin-top: -60px;
  border: 0px;
  resize:none ;
}
.youxiang-right textarea:focus{
  outline: none;
}
.send-button{
  width: 139px;
  height: 40px;
  background: #258B3B;
  //border-radius: 20px;
  margin-left: 100px;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: bold;
  color: #FFFFFF;
  line-height:40px;
  text-align: center;


}
.send-button:hover{
  background: white;
  color:#258B3B;
  border:1px solid #258B3B;
}
@import url("../assets/css/st.css");
</style>
