<template>
  <div>
    <div class="bottom">

      <a href="/">
        <img src="../../public/img/bottom-logo.png" alt="" style="margin-top: 109px">
      </a>
      <div class="bottom-link">
        <router-link to="/home">
          <div class="bottom-link-item">

            Home

          </div>
        </router-link>
        <router-link to="/product">
          <div class="bottom-link-item">
            Product
          </div>
        </router-link>
        <router-link to="/company">
          <div class="bottom-link-item">
            Company
          </div>
        </router-link>
        <router-link to="/support">
          <div class="bottom-link-item">
            Technology
          </div>
        </router-link>
        <router-link to="/news">
          <div class="bottom-link-item">
            News
          </div>
        </router-link>
        <router-link to="/contact">
          <div class="bottom-link-item">
            Contact Us
          </div>
        </router-link>
      </div>
      <div class="bottom-text">
        BEST TITANIUM PRODUCER SINCE 2008
      </div>

      <div class="bottom-shemei">
        <div class="bottom-shemei-item">
          <a href="https://www.facebook.com/">
            <img src="../../public/img/facebook.png" alt="">
          </a>
        </div>
        <div class="bottom-shemei-item">
          <a href="https://twitter.com/">
            <img src="../../public/img/niao.png" alt="">
          </a>
        </div>
        <div class="bottom-shemei-item">
          <a href="http://instagram.com/">
            <img src="../../public/img/ins.png" alt="">
          </a>
        </div>
      </div>
      <div class="right-content">

      </div>
    </div>
    <div class="beian"><a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备13016344号-1</a></div>
  </div>
</template>

<script>
export default {
name: "Bottom"
}
</script>

<style scoped>
.bottom{
  height: 549px;
  background: url("/img/bottom-back.png");
  text-align: center;
}
.bottom-link{
  display: flex;
  width: 750px;
  margin:  0 auto;
  justify-content: space-between;
  color: white;
  margin-top: 47px;
}
.bottom-link-item{
  padding: 7px 25px 6px 25px;
  color: white;
  text-decoration: none;
  font-family: Ubuntu-b;

}
.router-link-active .bottom-link-item{
  background: #258B3B;

}
.bottom-text{
  font-family: Rajdhani-bold;
  font-size: 36px;
  color: white;
  margin-top: 40px;
}
.bottom-shemei{
  display: flex;
  width: 200px;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 20px;
}
.beian{background: #0A1229;padding: 14px 0;text-align: center;color: white;}
.beian a{color: white;}
</style>
