import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta:{
      title:'Zhejiang SHENJI Titanium Industry Co.,Ltd. | Home'
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title:'Zhejiang SHENJI Titanium Industry Co.,Ltd. | Home'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta:{
      title:'Zhejiang SHENJI Titanium Industry Co.,Ltd. | Home'
    }
  },
  {
    path: '/product',
    name: 'Product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue'),
    meta:{
      title:'Zhejiang SHENJI Titanium Industry Co.,Ltd. | Product'
    }
  },
  {
    path: '/company',
    name: 'Company',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Company.vue'),
    meta:{
      title:'Zhejiang SHENJI Titanium Industry Co.,Ltd. | Company'
    }
  },
  {
    path: '/support',
    name: 'Support',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Support.vue'),
    meta:{
      title:'Zhejiang SHENJI Titanium Industry Co.,Ltd. | Support'
    }
  },
  {
    path: '/news',
    name: 'News',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue'),
    meta:{
      title:'Zhejiang SHENJI Titanium Industry Co.,Ltd. | News'
    }
  },
  {
    path: '/newsDe',
    name: 'NewsDe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsDe.vue'),
    meta:{
      title:'Zhejiang SHENJI Titanium Industry Co.,Ltd. | NewsDe'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
    meta:{
      title:'Zhejiang SHENJI Titanium Industry Co.,Ltd. | Contact'
    }
  },
  {
    path: '/productD',
    name: 'ProductD',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductD.vue'),
    meta:{
      title:'Zhejiang SHENJI Titanium Industry Co.,Ltd. | ProductD'
    }
  },
  {
    path: '/tecNew',
    name: 'tecNew',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/tecNew.vue'),
    meta:{
      title: 'Zhejiang SHENJI Titanium Industry Co.,Ltd. | Technology'
    }
  },
  {
    path: '/blank',
    name: 'blank',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/blank.vue'),
    meta:{
      title: 'Zhejiang SHENJI Titanium Industry Co.,Ltd. | Technology'
    }
  },
]

const router = new VueRouter({
  routes,
mode:'history'
})

export default router
