<template>
  <div id="app">
    <div id="nav">
      <HelloWorld></HelloWorld>
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
    </div>
    <router-view/>
<!--    <Bottom></Bottom>-->
  </div>
</template>

<script>
import HelloWorld from "@/components/HelloWorld";
// import Bottom from "@/components/Bottom";
export default {
  components:{
    // Bottom,
    HelloWorld
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      // console.log(flag)
      return flag;
    },
    getnav(index){
      this.getnavIndex=index
      // console.log('getnavIndex:'+this.getnavIndex)
    }
  },
  created() {
    let abc = document.getElementsByTagName('body')[0]
    if (this._isMobile()) {
      abc.style.width = 1920+'px'
      // console.log(abc.style.width)
    }else{
      // let abc = document.getElementsByTagName('body')[0]
      let width = document.body.clientWidth / 1920
      abc.style.zoom = width
      // console.log(abc.style.zoom)
    }
  },
}
</script>
<style lang="less">
body{
margin: 0px;
}
p{
  margin-bottom: 0px!important;
}
</style>
