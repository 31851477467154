<template>
  <div class="home">
    <div class="home-banner">
        <div class="home-banner">
          <splide :options="homeindus1" id="home-banner-pic">
            <splide-slide>
              <div class="home-banner-item">
                <img src="../../public/img/home-banner-feiji.jpg" alt="" width="100%" height="833px">
                <div class="home-banner-item-text">
                  <p class="home-banner-item-text-1 wow animate__animated animate__bounceInLeft">
                    Aerospace Quality Titanium
                  </p>
                  <p class="home-banner-item-text-2">
                    A Leading Manufacturer of Aerospace Quality Titanium
                  </p>
                </div>
              </div>
            </splide-slide>
            <splide-slide>
              <div class="home-banner-item">
                <img src="../../public/img/home-banner-1.png" alt="" width="100%" height="833px">
                <div class="home-banner-item-text">
                  <p class="home-banner-item-text-1">
                    Commercially Pure Titanium Alloy
                  </p>
                  <p class="home-banner-item-text-2">
                    A Leading Manufacturer of Commercially Pure Titanium Alloy Products
                  </p>
                </div>
              </div>
            </splide-slide>
          </splide>
        </div>
      <div class="home-banner-content-out">
      <div class="home-banner-content">
<div class="home-banner-content-img">
  <img src="../../public/img/home-banner-peo.png" alt="">
</div>
        <div class="home-banner-content-text">
          <p class="home-banner-content-text-title wow animate__animated animate__fadeInDown">
            Send Word
          </p>
          <p class="home-banner-content-text-text wow animate__animated animate__fadeInDown">
            For more than <span style="color: #258B3B;font-size:20px ">12</span> years, we are committed to create value for our customers, and achieve further value for both our employees and investors.

          </p>
<p class="home-banner-content-text-text" style="text-align: right">          ——Mr. Wu, CEO</p>
        </div>
      </div>
      </div>
    </div>
<!--    shouyezhuying-->
    <div class="home-main-cate">
        <p class="home-main-cate-title-common wow animate__animated animate__fadeInDown">
          MAIN CATEGORY
        </p>
      <div class="home-main-cate-content">
          <div class="home-main-cate-content-item">
            <router-link
              :to="{
                      path:'ProductD',
                      query:{id:970}
                }" target="_blank">
            <img src="../../public/img/home-main-cate-pic1-new.png" alt="">
            <div class="home-now-recommended-content-item-text home-main-cate-content-item-text">
              <div >

              </div>

              <div >

                  Gr.4 Titanium Sheet 1.0mm >


              </div>
              <div></div>
            </div>
            </router-link>
          </div>
        <div class="home-main-cate-content-item">
          <router-link
            :to="{
                      path:'ProductD',
                      query:{id:971}
                }" target="_blank">
          <img src="../../public/img/home-main-cate-pic2-new.png" alt="">
          <div class="home-now-recommended-content-item-text home-main-cate-content-item-text">
            <div >

            </div>
            <div >

                AMS Gr.5 Titanium Sheet 0.81mm >


            </div>
            <div></div>

          </div>
          </router-link>
        </div>
      </div>
    </div>
<!--首页公司介绍-->
    <div class="home-company-intro">
      <p class="home-main-cate-title-common wow animate__animated animate__fadeInDown " STYLE="color:white;">
        COMPANY INTRODUCTION
<!--        FIRST-RATE SERVICE-->
      </p>

      <div class="home-company-intro-content">
        <div class="home-company-intro-content-left">
          Back in 2008, SHENJI Titanium Industry was founded in Zhejiang, China. Together with our trading company named Shentai which located in Shanghai, we have been dedicated to design, manufacture and supply titanium products, also a wide range of other titanium alloy products. We offer one-stop purchasing service to make commerce much easier. Nowadays, we have a factory of more than 38,000 square meters, and more than 200 employees. Commitment to excellence, sustainability, unrivaled services and factory-direct sales. So what are you waiting for? Come and tell us what you need!
       </div>
        <div class="home-company-intro-content-right">
          <div class="home-company-intro-content-right-item">
              <span style="font-family: Rajdhani-bold;font-size: 40px">12+</span>
          <br>
            Years Experience
          </div>
          <div class="home-company-intro-content-right-item">
            <span style="font-family: Rajdhani-bold;font-size: 40px">200+</span>
            <br>
            Employees
          </div>
          <div class="home-company-intro-content-right-item">
            <span style="font-family: Rajdhani-bold;font-size: 40px">38,636</span>
            <br>
            Square Meters
          </div>
        </div>
      </div>
      <div class="home-company-intro-video">
        <a >
        <div class="home-company-intro-video-anniu" @click="changeVideo(1)" v-if="this.videocanshu ==0">
          <span></span>

        </div>
        </a>
        <img src="../../public/img/home-com-intro-vid-pic.jpg" alt="" @click="changeVideo(1)" v-if="this.videocanshu ==0">
        <video controls="controls" width="1293"  autoplay v-if="this.videocanshu ==1">
          <source src="../../public/img/home-video.mp4" type="video/mp4">
        </video>
      </div>
    </div>
<!--    中间的介绍-->
    <div class="home-middle-contact">
      <div class="home-middle-contact-inner">
          <div class="home-middle-contact-inner-left">
            Buy Titanium Today<br>
          <span style="font-size: 20px">  Sheet / Plate / Mesh / Disc / Bar / Tube / Pipe / Wire</span>
          </div>
        <div class="home-middle-contact-inner-middle">

          <input type="text" placeholder="EMAIL" v-model="contactForm.email">
        </div>
        <div class="home-middle-contact-inner-right">
          <a >
          <div class="home-middle-contact-inner-right-anniu" @click="postContact()" v-loading="loading">

              Contact Us

          </div>
          </a>
        </div>
      </div>
    </div>
<!--    now recommended-->
    <div class="home-now-recommended">
      <p class="home-main-cate-title-common wow animate__animated animate__fadeInDown" >
        NOW RECOMMENDED
      </p>

      <div class="home-now-recommended-content" >
          <div class="home-now-recommended-content-item" v-for="item in hotShopsss" :key="item.num">
            <router-link
              :to="{
                      path:'ProductD',
                      query:{id:item.id}
                }" target="_blank">
            <img :src="'http://cdn.fish-cloud.com/'+item.img" alt="">
            <div class="home-now-recommended-content-item-text">
              <div >

              </div>


                  <div style="color: white" >
                    <router-link
                        :to="{
                      path:'ProductD',
                      query:{id:item.id}
                }" style="color: white">


                {{ item.name }}

                    </router-link>
              </div>

              <div></div>
            </div>
            </router-link>
          </div>
<!--        <div class="home-now-recommended-content-item">-->
<!--          <img src="../../public/img/home-now-recommended-pic.png" alt="">-->
<!--          <div class="home-now-recommended-content-item-text">-->
<!--            <div >-->

<!--            </div>-->
<!--            <div >-->
<!--              GR2 pure titanium plate 1.5mm >-->
<!--            </div>-->
<!--            <div></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="home-now-recommended-content-item">-->
<!--          <img src="../../public/img/home-now-recommended-pic.png" alt="">-->
<!--          <div class="home-now-recommended-content-item-text">-->
<!--            <div >-->

<!--            </div>-->
<!--            <div >-->
<!--              GR2 pure titanium plate 1.5mm >-->
<!--            </div>-->
<!--            <div></div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
<!--    first-sale service-->
    <div class="home-first-serve">
      <p class="home-main-cate-title-common wow animate__animated animate__fadeInDown" STYLE="color:white;">
<!--        COMPANY INTRODUCTION-->
        FIRST-RATE SERVICE
      </p>

      <div class="home-first-serve-content">
        <div class="home-first-serve-content-item">
          <div class="home-first-serve-content-item-pic">
            <img src="../../public/img/home-first-serve-pic1.png" alt=""></div>
          <div class="home-first-serve-content-item-text">
            <img src="../../public/img/home-first-serve-logo.png" alt="">
            <p class=" home-first-serve-content-item-text-title">
              Consulting</p>
            <p class="home-first-serve-content-item-text-text">
              Titanium comes in many sizes and shapes. It is important to choose the titanium that perfectly match your requirements. We can offer you expert advice and assistance, so that through our expert assistance, it is most likely for you to get the best titanium options.
            </p>
          </div>
        </div>
        <div class="home-first-serve-content-item">
          <div class="home-first-serve-content-item-pic">
            <img src="../../public/img/home-first-serve-pic2.png" alt=""></div>
          <div class="home-first-serve-content-item-text">
            <img src="../../public/img/home-first-serve-logo.png" alt="">
            <p class=" home-first-serve-content-item-text-title">
            Design</p>
            <p class="home-first-serve-content-item-text-text">
              A professional R&D team of 9 people is ready to offer you a wide range of design service. We will try our best to avoid any delay or poor communication caused by outsourcing to external companies. With a knowledgeable team, we are able to provide efficient services for every interested clients.
            </p>
          </div>
        </div>
      </div>
    </div>
<!--home-solution-->
    <div class="home-solution">
      <p class="home-main-cate-title-common home-solution-title wow animate__animated animate__fadeInDown" STYLE="color:white;">
        SOLUTION
      </p>
      <div class="home-solution-left home-solution-item">
        <div class="home-solution-item-inner">
          <p class="home-solution-item-inner-title">
            Aerospace Industry
          </p>
          <p class="home-solution-item-inner-text">
            We provide high-performance specialty metals for critical applications in the aerospace market, including commercial airframe, engine, internal and external systems, military and defense, rotors, space and many other applications. We provide global service at competitive prices, guarantee quality, and deliver the best delivery to all levels of the aerospace supply chain.
          </p>
        </div>
      </div>
      <div class="home-solution-middle home-solution-item">
        <div class="home-solution-item-inner">
          <p class="home-solution-item-inner-title">
            Power Generation
          </p>
          <p class="home-solution-item-inner-text">
            Titanium is utilized in the power generation industry in turbine blade applications. Besides, titanium tubing is used on a large scale in the condenser and auxillary heat exchanger applications in power plants due to its corrosion resistance and its unlimited life span.
          </p>
        </div>
      </div>
      <div class="home-solution-right home-solution-item">
        <div class="home-solution-item-inner">
          <p class="home-solution-item-inner-title">
            Oil And Gas
          </p>
          <p class="home-solution-item-inner-text">
            Oil and gas concentrate the key resources and capabilities of the titanium industry in the global oil and gas market. By providing titanium and specialty metals with superalloys, we are able to provide high-strength, corrosion-resistant materials for the most challenging oil and gas applications and environments, such as exploration, production and refining.
          </p>
        </div>
      </div>
      <div class="home-solution-right-right home-solution-item">
        <div class="home-solution-item-inner">
          <p class="home-solution-item-inner-title">
            Medical Treatment
          </p>
          <p class="home-solution-item-inner-text">
            As a new type of alloy, medical titanium alloy is widely used in related fields such as limb implants, alternative functional materials, dentistry, and medical equipment. Titanium and titanium alloys have the following advantages: good corrosion resistance, high specific strength, low elastic modulus, fatigue resistance, and good biocompatibility.
          </p>
        </div>
      </div>
    </div>
<!--    home-custom-review-->
    <div  class="home-custom-review">
      <div class="home-custom-review-item">
<div >
  <img src="../../public/img/home-custom-review-left.png" alt="">
</div>
        <div class="home-custom-review-item-right">
          <div class="home-custom-review-item-right-text-title wow animate__animated animate__bounceInRight">
            CUSTOMER REVIEW
          </div>
            <div style="display: flex ;border-top: 1px">
              <div class="home-custom-review-item-right-peo">
              <div class="home-custom-review-item-right-peo-pic">
                <img :src="this.picList[this.picIndex]" alt="">
              </div>


          </div>
              <div class="home-custom-review-item-right-text">

                  <splide :options="option1"
                          @splide:moved="onMounted">
                  <splide-slide>
                    <div class="home-custom-review-item-right-text-text">
                      Shenji is a supplier to us. They have a very high stable quality and we appreciate their good service. We can always relay on getting the material just in time.
                    </div>
                    <div class="home-custom-review-item-right-text-text-peo">
                      <span style="color:#258B3B;">Sweden</span>
                    </div>
                  </splide-slide>
                    <splide-slide>
                      <div class="home-custom-review-item-right-text-text">
                        We are very satisfied with the top quality of Ti Gr.5 and Ti Gr. 2 sheets which we received from your company. We have never got any claim up to now. Also the delivery date and service are very reliable.
                      </div>
                      <div class="home-custom-review-item-right-text-text-peo">
                        <span style="color:#258B3B;"> Germany</span>
                      </div>
                    </splide-slide>
                    <splide-slide>
                      <div class="home-custom-review-item-right-text-text">
                        Excellent Titanium sheets and plates! Plant really stands behind quality and short production time. Shenji is also capable to manufacture aerospace quality material per AMS standards.
                    </div>
                      <div class="home-custom-review-item-right-text-text-peo">
                       <span style="color:#258B3B;"> USA</span>
                      </div>
                    </splide-slide>
                    <splide-slide>
                      <div class="home-custom-review-item-right-text-text">
                        We are happy to tell you that your titanium plates are perfect. We'd like to thank you again for your hospitality and help during our visit to your company.
                    </div>
                      <div class="home-custom-review-item-right-text-text-peo">
                       <span style="color:#258B3B;"> Italy</span>
                      </div>
                    </splide-slide>
                    <splide-slide>
                      <div class="home-custom-review-item-right-text-text">
                        Our goods arrived on time without any delay. Thank you very much for completing such a large order perfectly. Looking forward to our next cooperation!
                    </div>
                      <div class="home-custom-review-item-right-text-text-peo">
                         <span style="color:#258B3B;"> Australia</span>
                      </div>
                    </splide-slide>
                    <splide-slide>
                      <div class="home-custom-review-item-right-text-text">
                        Everything is great. Very happy to work with Tina, who has helped us get everything straight. We will certainly order again next time!

                      </div>
                      <div class="home-custom-review-item-right-text-text-peo">
                       <span style="color:#258B3B;"> Russia</span>
                      </div>
                    </splide-slide>
                  </splide>
          </div>
            </div>
        </div>
      </div>

    </div>

<!--    home-recent-news-->
    <div class="home-recent-news">
        <div class="home-custom-review-inner">
          <div class="home-custom-review-inner-left">
            <p class="home-custom-review-inner-left-title wow animate__animated animate__bounceInLeft">
              RECENT NEWS
            </p>
            <a >
            <div  :class="['home-custom-review-inner-left-title-item',{'home-news-active':this.newIndex==1}]" style="margin-top: 73px" @click="changeNewIndex(1,catInfo[0].id)">
              Company News >
            </div>
            </a>
            <a>
            <div  :class="['home-custom-review-inner-left-title-item',{'home-news-active':this.newIndex==2}]" style="margin-top:31px ;" @click="changeNewIndex(2,catInfo[1].id)">
              Industrial News >
            </div>
            </a>
          </div>

          <div class="home-custom-review-inner-right" v-for="item in newList" :key="item.num">
            <router-link
              :to="{
                      path:'/newsDe',
                      query:{id:item.id}
                }">
            <img :src="'http://cdn.fish-cloud.com/'+item.articleImg" alt="" class="home-custom-review-inner-right-pic">
            <div class="home-custom-review-inner-right-text">
              <p class="home-custom-review-inner-right-time">
                {{item.releaseDate }}
              </p>
              <p class="home-custom-review-inner-right-title">
                {{ item.name }}
              </p>
              <p class="home-custom-review-inner-right-text-text" v-html="item.articleContent" >

              </p>


            </div>
          </router-link>
          </div>


        </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import {Splide,SplideSlide} from  '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import {WOW} from 'wowjs'
import Bottom from "@/components/Bottom";

export default {
  name: 'Home',
  components: {
    Splide,
    SplideSlide,
    Bottom
  },
  data(){
    return{
      homeindus1:{
        perPage:1,
        // gap:10,
        type:'loop',
        perMove:1,
        autoplay:true,
        interval:4000,
        // width:732
      },
      option1:{
        perPage:1,
        // gap:10,
        type:'loop',
        perMove:1,
        autoplay:true,
        interval:2000,
        width:567
      },
      picIndex:0,
      picList:[
          '/img/home-rentou7.png',
          '/img/home-rentou6.png',
          '/img/home-rentou5.png',
          '/img/home-rentou1.png',
          '/img/home-rentou3.png',
          '/img/home-rentou2.png',
      ],
      newIndex:1,
      videocanshu:0,
      hotShopsss:[],
      contactForm:{
        name: "no name",
        jobTitle: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        message: "no message"
      },
      newList2:[],
      newList1:[],
      catInfo:[],
      newsInnercanshu:{
        pageIndex: 0,
        pageSize: 3,
        totalCount: 0,
        catId:'',
        datas: [
          {
            id: 0,
            articleUrl: "string",
            articleImg: "string",
            articleContent: "string",
            articleAbstract: "string",
            releaseDate: "2021-02-01T03:46:32.146Z",
            userId: 0,
            name: "string",
            seoTitle: "string",
            seoKeyWords: "string",
            seoDescription: "string",
            someItemsId: [
              0
            ],
            articleAuthor: "string",
            articleForm: "string",
            articleWebUrl: "string",
            browseCount: 0
          }
        ],


      },
      newList:[],
      loading:false,
    }
  },
  created() {
    this.getdata()
  },
  methods:{
    getdata(){
      this.$axios.get('api/hotShops').then(res=>{
      this.hotShopsss =res.data
      })



        this.$axios.get('/api/cats',{params:{catType:1}}).then(res=>{
          this.catInfo = res.data
          console.log(this.catInfo[0].id,'123')

        })

        this.newsInnercanshu.catId = 308
        this.$axios.get('/api/news', {params:this.newsInnercanshu}).then(res=>{
          this.newList=res.data.datas
          this.newList1 = res.data.datas

        })
        this.newsInnercanshu.catId = 309
        this.$axios.get('/api/news', {params:this.newsInnercanshu}).then(res=>{
          // this.newList=res.data.datas
          this.newList2 = res.data.datas

        })

    },
    onMounted() {

      if (this.picIndex<6){
        this.picIndex=this.picIndex+1
      }
      if(this.picIndex==6){
        this.picIndex = 0
      }
// console.log(this.picIndex)
    },
    changeNewIndex(e,f){
      this.newIndex=e,
        this.newsInnercanshu.catId = f
      this.$axios.get('/api/news', {params:this.newsInnercanshu}).then(res=>{
        this.newList=res.data.datas

      })
},
    changeVideo(e){
     this.videocanshu =e
},
    postContact(){
      const is=this.formCheck(this.contactForm,2)
      if(is.is_ok==true){
        if(this.loading==false){
          this.loading=true
          this.$axios.post('/api/pushContact',this.contactForm).then(res=>{
            this.loading=false
            if(res.data.code==200){
              this.$message.success('Successed!');
            }else{
              this.$message.error('Failed!');
            }
          })
        }
      }else if(is.is_empty==true){
        // this.loading=false
        // this.$message.warning('The form can\'t be empty!');
        this.$message({message: 'The form can\'t be empty!',type: 'warning',offset:430,})
      }else{
        this.$message({message: 'Failed!',type: 'error',offset:430,})
      }
    }
  },
  mounted() {
    new WOW().init()
  },


}
</script>

<style lang="less">
@import url("../assets/css/st.css");
</style>
