import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import Swiper  from "swiper";
import vueSwiper from 'vue-awesome-swiper'
import 'animate.css'
import 'swiper/dist/css/swiper.css';
import axios from 'axios';
import Carousel3d from 'vue-carousel-3d'
import {Modal,Input,Pagination} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import Share from 'vue-social-share'
import 'vue-social-share/dist/client.css';
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import mockdata from './mock'
Vue.use(mockdata)
Vue.use(Modal)
Vue.use(Input)
Vue.use(Carousel3d)
Vue.use(vueSwiper)
Vue.use(Pagination)
Vue.use(VueAwesomeSwiper)
Vue.use(Share)
Vue.use(ElementUI);
Vue.config.productionTip = false
// Vue.use(Swiper)
Vue.prototype.$axios =axios
axios.defaults.baseURL= '/'
// axios.defaults.baseURL= 'https://www.shenjiti.com'

Vue.prototype.formCheck=(form,type)=>{//表单格式判断,type1：产品询盘，2：联系询盘
    // console.log(form)
    let num=0,is_ok=false,is_empty=true
    switch (type){//判断是否空值
        case 1:
            if(form.name!='' && form.email!='' && form.VerifyCode!='' && form.message!=''){
                num+=1
                is_empty=false
            }
            break;
        case 2:
            if(form.name!='' && form.email!='' && form.message!=''){
                num+=1
                is_empty=false
            }
            break;
    }
    //邮箱格式判断
    const str=/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    if(str.test(form.email)==true){
        num+=1
    }
    if(num>=2 && is_empty==false){
        is_ok=true
    }
    // console.log(is_ok,is_empty)
    return {is_ok:is_ok,is_empty:is_empty,}
}
router.beforeEach((to,from,next) => {
    if (to.meta.title){
      document.title = to.meta.title
    }
    next()
  }
)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
import '@/common/font/font.css';
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  window.scrollTo(0,0);
  next()
})


